import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout"
import PageBanner from '../components/PageBanner'

import appletouch from '../img/apple-touch-icon.png'
import favicon from '../img/favicon.png'
import favicon32 from '../img/favicon-32x32.png'
import favicon16 from '../img/favicon-16x16.png'
import maskIcon from '../img/safari-pinned-tab.svg'

const ReactDOM = require('react-dom')
const BlockContent = require('@sanity/block-content-to-react')

const sanityClient = require('@sanity/client')
const client = sanityClient({
  projectId: 'a8s3bknf',
  dataset: 'production',
  imageOptions: {w: 320, h: 240, fit: 'max'},
  token: process.env.MY_SANITY_TOKEN, // or leave blank to be anonymous user
  useCdn: false // `false` if you want to ensure fresh data
})

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    divider: props => (
      <hr />
    )
  }
}

class Template extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      postHtml: '',
      postTitle: ''
    }
  }

  componentDidMount() {
    const postSlug = this.props.location.pathname.slice(1);
  
    const query = `*[_type == "post" && slug.current == "${postSlug}"] {title, slug, body}`

    client.fetch(query).then(article => {
      // ? console.log("ARTICLE", article)
      ReactDOM.render(
        <BlockContent blocks={article[0].body} serializers={serializers} projectId='a8s3bknf' dataset='production' />,
        document.getElementById('postContent')
      )
      this.setState({postTitle: article[0].title})
    })
  }

  render(){
    //? console.log(this.state);
  return (
    <Layout>

      <Helmet
          title={this.state.postTitle}
          meta={[
            { name: 'description', content: 'What would happen if Cayde spilled ramen on Rasputin\'s records of the Golden Age? Probably something like this.' },
            { name: 'msapplication-TileColor', content: '#ffc40d' },
            { name: 'theme-color', content: '#ffffff' },
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no'},
            { content: 'Chris Landtiser', name: 'author' },
            { content: 'en_US', property: 'og:locale'},
            { content: 'website', property: 'og:type'},
            { content: 'Not Bungie', property: 'og:title'},
            { content: 'What would happen if Cayde spilled ramen on Rasputin\'s records of the Golden Age? Probably something like this.', property: 'og:description'},
            { content: 'https://notbungie.net', property: 'og:url'},
            { content: 'Not Bungie', property: 'og:site_name'},
            { content: 'og:image', property: `${favicon}`},
            { content: '@landtiser', property: 'twitter:creator'},
        ]}
        link={[
            { rel: 'apple-touch-icon', sizes: '180x180', href: `${appletouch}` },
            { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
            { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
            { rel: 'manifest', href: `/site.webmanifest` },
            { rel: 'mask-icon', color: '#5bbad5', href: `${maskIcon}` },
        ]}
      />

      <div className="blog-post-container">
        <Helmet title={this.state.postTitle} />
        <PageBanner />
          <div className="blog-post">
              <div className="blog-post-content" id="postContent" />
          </div>
      </div>
    </Layout>
  )}
}

export default Template;